import { Request } from "@/services/api";
import { IOrders } from "@/types/types";

export class OrdersService {
  static list(page: number, size: number, query?: string) {
    return Request().get(
      `/orders?page=${page}&size=${size}${query ? query : ""}`
    );
  }

  static details(id: string) {
    return Request().get(`/orders/${id}`);
  }

  static update(id: string, body: Pick<IOrders, "status">) {
    return Request().put(`/orders/${id}`, body);
  }

  static dashboardCount() {
    return Request().get(`/reports/total/list`);
  }

  static dashboardGraph() {
    return Request().get(`/reports/graph`);
  }

  static publish(payload: { id: string; isPublished: boolean }) {
    return Request().put(`/products/${payload.id}/publish`, payload);
  }

  static moveToTrash(payload: { id: string; isTrashed: boolean }) {
    return Request().put(`/products/${payload.id}/trash`, payload);
  }

  static moveToArchives(payload: { id: string; isArchived: boolean }) {
    return Request().put(`/products/${payload.id}/archive`, payload);
  }

  static trash() {
    return Request().get(`/products/trash/list`);
  }

  static archives() {
    return Request().get(`/products/archives/list`);
  }

  static deleteAll() {
    return Request().delete(`/products/clear/trash`);
  }
}
