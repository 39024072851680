// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyBYPUH637pa0_XrwqxtaWvif_9_-Vc6lSw",
  authDomain: "kairos-food-services.firebaseapp.com",
  projectId: "kairos-food-services",
  storageBucket: "kairos-food-services.appspot.com",
  messagingSenderId: "771325083678",
  appId: "1:771325083678:web:5db9eaa0c41098e94d2e0f",
  measurementId: "G-4HH9JVWZQY"
};
