import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import {
  IDialogAndButtonAction,
  IUser,
  RootState,
  StoreState
} from "@/types/types";
import { orderBy } from "lodash";
import { isNetworkError } from "@/utils/helpers";
import UsersService from "@/services/users.service";

const namespaced = true;

const state: StoreState<IUser> = {
  all: [],
  details: {
    name: "",
    email: "",
    mobile: ""
  },
  counter: [],
  managers: [],
  state: "asc",
  reset: {
    name: "",
    email: "",
    role: "",
    mobile: "",
    userType: ""
  },
  pk: ""
};

const mutations: MutationTree<StoreState<IUser>> = {
  UPDATE_USER_LIST(state, payload: IUser[]) {
    state.all = payload;
  },
  UPDATE_USER_DETAILS(state, payload: IUser) {
    state.details = payload;
  },
  UPDATE_ORDER_STATE(state, payload: string) {
    state.state = payload;
  },
  UPDATE_COUNTER_USERS_LIST(state, payload: IUser) {
    state.counter = payload;
  },
  UPDATE_MANAGER_LIST(state, payload: IUser) {
    state.managers = payload;
  },
  UPDATE_PRIMARY_KEY(state, payload: string) {
    state.pk = payload;
  }
};

const actions: ActionTree<StoreState<IUser>, RootState> = {
  async list({ commit, dispatch }) {
    try {
      dispatch("isPageLoading", true, { root: true });
      const response = await UsersService.list();
      if (response) {
        commit("UPDATE_USER_LIST", response?.data);
        dispatch("internet", false, { root: true });
        dispatch("isPageLoading", false, { root: true });
      }
    } catch (e) {
      dispatch("isPageLoading", false, { root: true });
      if (isNetworkError(e)) {
        dispatch("internet", true, { root: true });
        dispatch("snackBarMessage", "Check your internet connection", {
          root: true
        });
      } else {
        dispatch("snackBarMessage", e.response.data?.message, { root: true });
      }
      dispatch("snackBarVisibility", true, { root: true });
    }
  },
  async create({ commit, dispatch }, payload: IDialogAndButtonAction<IUser>) {
    try {
      dispatch("isLoading", true, { root: true });
      const response = await UsersService.create(payload.body);
      if (response) {
        dispatch("updateDialog", payload.loading, { root: true });
        dispatch("snackBarVisibility", true, { root: true });
        dispatch("snackBarMessage", "User created successfully", {
          root: true
        });
        dispatch("resetFormValues", true, { root: true });
        dispatch("isLoading", false, { root: true });
        dispatch("list");
      }
    } catch (e) {
      dispatch("isLoading", false, { root: true });
      if (isNetworkError(e)) {
        dispatch(
          "snackBarMessage",
          e?.message ?? "Check your internet connection",
          {
            root: true
          }
        );
      } else {
        dispatch("snackBarMessage", "Unable to save user details", {
          root: true
        });
      }
      dispatch("snackBarVisibility", true, { root: true });
    }
  },
  async details({ state, commit }, id: string) {
    const results = state.all.find((user: IUser) => user.id === id);
    commit("UPDATE_USER_DETAILS", results);
  },
  async detailsFromApi({ commit, dispatch }, id: string) {
    try {
      dispatch("isPageLoading", true, { root: true });
      const response = await UsersService.find(id);
      if (response) {
        commit("UPDATE_USER_DETAILS", response.data?.details);
        dispatch("isPageLoading", false, { root: true });
        dispatch("notFound", false, { root: true });
      }
    } catch (e) {
      dispatch("isPageLoading", false, { root: true });
      if (isNetworkError(e)) {
        dispatch("snackBarMessage", "Check your internet connection", {
          root: true
        });
      } else {
        if (e.response.status === 404) {
          dispatch("notFound", true, { root: true });
        }
        dispatch("snackBarMessage", e.response.data?.message, { root: true });
      }
      dispatch("snackBarVisibility", true, { root: true });
    }
  },
  async pk({ commit }, payload) {
    const pk = state.all.find(item => item.id === payload);
    commit("UPDATE_PRIMARY_KEY", pk?.id);
  },
  async update({ commit, dispatch }, payload: IDialogAndButtonAction<IUser>) {
    try {
      dispatch("isLoading", true, { root: true });
      const response = await UsersService.update(
        payload?.body?.id,
        payload.body
      );
      if (response) {
        dispatch("list");
        dispatch("isLoading", false, { root: true });
        dispatch("updateDialog", payload?.loading, { root: true });
        dispatch("snackBarMessage", "User details updated successfully", {
          root: true
        });
        dispatch("snackBarVisibility", true, { root: true });
      }
    } catch (e) {
      dispatch("isLoading", false, { root: true });
      if (isNetworkError(e)) {
        dispatch("snackBarMessage", "Check your internet connection", {
          root: true
        });
      } else {
        dispatch("snackBarMessage", e.response?.message, { root: true });
      }
      dispatch("snackBarVisibility", true, { root: true });
    }
  },
  async delete({ commit, dispatch }, payload: IDialogAndButtonAction<string>) {
    try {
      dispatch("isLoading", true, { root: true });
      const response = await UsersService.delete(payload?.id);
      if (response) {
        dispatch("updateDialog", payload?.loading, { root: true });
        dispatch("snackBarVisibility", true, { root: true });
        dispatch("snackBarMessage", "User details deleted successfully", {
          root: true
        });
        dispatch("isLoading", false, { root: true });
        dispatch("list");
      }
    } catch (e) {
      dispatch("isLoading", false, { root: true });
      if (isNetworkError(e)) {
        dispatch("snackBarMessage", e?.message, {
          root: true
        });
      } else {
        dispatch("snackBarMessage", e.response?.message, { root: true });
      }
      dispatch("snackBarVisibility", true, { root: true });
    }
  },
  async orderBy({ commit, state }, payload: { iteratee: string }) {
    if (state.state === "asc") {
      commit("UPDATE_ORDER_STATE", "desc");
    } else commit("UPDATE_ORDER_STATE", "asc");
    const results = orderBy(state.all, [payload.iteratee], state.state);
    commit("UPDATE_USER_LIST", results);
  }
};

const getters: GetterTree<StoreState<IUser>, RootState> = {
  getAllUsers: state => state.all,
  getPrimaryKey: state => state.pk,
  getUserDetails: state => state.details,
  getCountingUsers: state => state.counter,
  getManagersList: state => state.managers,
  getResetFormValues: state => state.reset
};

export const users: Module<StoreState<IUser>, RootState> = {
  namespaced,
  state,
  mutations,
  actions,
  getters
};
