import {
  IDialogAndButtonAction,
  IOrders,
  IPaginate,
  PayloadState,
  RootState,
  StoreState
} from "@/types/types";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { isNetworkError } from "@/utils/helpers";
import { OrdersService } from "@/services/orders.service";

const namespaced: boolean = true;
const state: StoreState<IOrders> = {
  all: [],
  details: {
    _id: "",
    accountNumber: "",
    isPaymentMade: false,
    orderNumber: "",
    organizationName: "",
    package: "",
    packageXtras: [],
    status: ""
  },
  paginate: {
    total: 0,
    itemsPerPage: 15,
    page: 1
  },
  trash: [],
  archives: [],
  dashboardCount: {
    ordersCount: 0,
    pendingOrderCount: 0,
    completedOrderCount: 0,
    overallTransactionCount: 0,
    totalOrderAmtForToday: 0
  },
  dashboardGraph: {
    totalAmountOfOrdersByDate: [],
    totalCountOfOrdersByDate: []
  }
};

const mutations: MutationTree<StoreState<IOrders>> = {
  UPDATE_ORDERS_LIST(state, payload: IOrders[]) {
    state.all = payload;
  },
  UPDATE_PRODUCT_DETAILS(state, payload: IOrders) {
    state.details = payload;
  },
  UPDATE_TRASH_LIST(state, payload: IOrders[]) {
    state.trash = payload;
  },
  UPDATE_ARCHIVES_LIST(state, payload: IOrders[]) {
    state.archives = payload;
  },
  UPDATE_PAGINATION(state, payload: IPaginate) {
    state.paginate = payload;
  },
  UPDATE_DASHBOARD_COUNT(state, payload) {
    state.dashboardCount = payload;
  },
  UPDATE_DASHBOARD_GRAPH(state, payload) {
    state.dashboardGraph = payload;
  }
};

const actions: ActionTree<StoreState<IOrders>, RootState> = {
  async list({ commit, dispatch }, payload: IPaginate & { query?: string }) {
    try {
      dispatch("isPageLoading", true, { root: true });
      const response = await OrdersService.list(
        payload.page,
        payload.itemsPerPage,
        payload?.query
      );
      if (response) {
        const { docs, page, limit, totalPages } = response?.data?.data;
        dispatch("isPageLoading", false, { root: true });
        commit("UPDATE_ORDERS_LIST", docs);
        commit("UPDATE_PAGINATION", {
          page,
          itemsPerPage: limit,
          total: totalPages
        });
      }
    } catch (e) {
      dispatch("isPageLoading", false, { root: true });
      if (isNetworkError(e)) {
        dispatch(
          "snackBarMessage",
          e?.message ?? "Unable to fetch orders list",
          { root: true }
        );
      } else {
        dispatch(
          "snackBarMessage",
          e?.response?.data?.message ?? "Unable to fetch orders",
          { root: true }
        );
      }
      dispatch("snackBarVisibility", true, { root: true });
    }
  },
  async details({ commit, dispatch }, id: string) {
    try {
      dispatch("isPageLoading", true, { root: true });
      const response = await OrdersService.details(id);
      if (response) {
        commit("UPDATE_PRODUCT_DETAILS", {
          ...response.data?.data
        });
        dispatch("isPageLoading", false, { root: true });
      }
    } catch (e) {
      dispatch("isPageLoading", false, { root: true });
      if (isNetworkError(e)) {
        dispatch(
          "snackBarMessage",
          e?.message ?? "Unable to fetch orders list",
          { root: true }
        );
      } else {
        dispatch(
          "snackBarMessage",
          e?.response?.data?.message ?? "Unable to fetch orders",
          { root: true }
        );
      }
      dispatch("snackBarVisibility", true, { root: true });
    }
  },

  async update(
    { commit, dispatch },
    payload: {
      id: string;
      body: Pick<IOrders, "status"> & { comment: string };
    }
  ) {
    try {
      dispatch("isLoading", true, { root: true });
      const response = await OrdersService.update(payload.id, payload.body);
      if (response.data) {
        dispatch("isLoading", false, { root: true });
        dispatch("snackBarMessage", "Orders mark as completed", {
          root: true
        });
        dispatch("snackBarVisibility", true, { root: true });
        dispatch("updateDialog", { idx: "add", state: false }, { root: true });
        dispatch("list", { page: 1, itemsPerPage: 15 });
      }
    } catch (e) {
      dispatch("isLoading", false, { root: true });
      dispatch("snackBarVisibility", true, { root: true });
      if (isNetworkError(e)) {
        dispatch("snackBarMessage", e?.message ?? "Unable to update product", {
          root: true
        });
      } else {
        dispatch(
          "snackBarMessage",
          e?.response?.data?.message ?? "Unable to update product",
          { root: true }
        );
      }
    }
  },
  async publishProduct(
    { commit, dispatch },
    payload: { id: string; isPublished: boolean }
  ) {
    try {
      dispatch("isLoading", true, { root: true });
      const response = await OrdersService.publish(payload);
      if (response) {
        dispatch("isLoading", false, { root: true });
        dispatch(
          "snackBarMessage",
          `Product ${
            payload.isPublished ? "published" : "unpublished"
          } successfully`,
          { root: true }
        );
        dispatch("snackBarVisibility", true, { root: true });
        dispatch("list");
      }
    } catch (e) {
      dispatch("isLoading", false, { root: true });
      dispatch("snackBarVisibility", true, { root: true });
      if (isNetworkError(e)) {
        dispatch("snackBarMessage", e?.message ?? "Unable to publish product", {
          root: true
        });
      } else {
        dispatch(
          "snackBarMessage",
          e?.response?.data?.message ?? "Unable to publish product",
          { root: true }
        );
      }
    }
  },
  async moveToArchive(
    { commit, dispatch },
    payload: { id: string; isArchived: boolean }
  ) {
    try {
      const response = await OrdersService.moveToArchives(payload);
      if (response) {
        dispatch("list");
        dispatch("archives");
        dispatch("snackBarVisibility", true, { root: true });
        dispatch("snackBarMessage", "Product archived successfully", {
          root: true
        });
      }
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch("snackBarMessage", e?.message ?? "Unable to create product", {
          root: true
        });
      } else {
        dispatch(
          "snackBarMessage",
          e?.response?.data?.message ?? "Unable to create product",
          { root: true }
        );
      }
    }
  },
  async moveToTrash(
    { commit, dispatch },
    payload: { id: string; isTrashed: boolean }
  ) {
    try {
      const { isTrashed } = payload;
      const response = await OrdersService.moveToTrash(payload);
      if (response) {
        dispatch("trash");
        dispatch("list");
        dispatch("snackBarVisibility", true, { root: true });
        dispatch(
          "snackBarMessage",
          isTrashed
            ? "Product trashed successfully"
            : "Product restored successfully",
          {
            root: true
          }
        );
      }
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch("snackBarMessage", e?.message ?? "Unable to create product", {
          root: true
        });
      } else {
        dispatch(
          "snackBarMessage",
          e?.response?.data?.message ?? "Unable to create product",
          { root: true }
        );
      }
    }
  },

  async archives({ commit, dispatch }) {
    try {
      dispatch("isPageLoading", true, { root: true });
      const response = await OrdersService.archives();
      if (response) {
        dispatch("isPageLoading", false, { root: true });
        commit("UPDATE_ARCHIVES_LIST", response.data);
      }
    } catch (e) {
      dispatch("isPageLoading", false, { root: true });
      if (isNetworkError(e)) {
        dispatch(
          "snackBarMessage",
          e?.message ?? "Unable to list archived orders",
          {
            root: true
          }
        );
      } else {
        dispatch(
          "snackBarMessage",
          e?.response?.data?.message ?? "Unable to list archived orders",
          { root: true }
        );
      }
    }
  },

  async trash({ commit, dispatch }) {
    try {
      dispatch("isPageLoading", true, { root: true });
      const response = await OrdersService.trash();
      if (response) {
        dispatch("isPageLoading", false, { root: true });
        commit("UPDATE_TRASH_LIST", response.data);
      }
    } catch (e) {
      dispatch("isPageLoading", false, { root: true });
      if (isNetworkError(e)) {
        dispatch(
          "snackBarMessage",
          e?.message ?? "Unable to list trashed orders",
          {
            root: true
          }
        );
      } else {
        dispatch(
          "snackBarMessage",
          e?.response?.data?.message ?? "Unable to list trashed orders",
          { root: true }
        );
      }
    }
  },
  async clearTrash(
    { commit, dispatch },
    payload: IDialogAndButtonAction<PayloadState>
  ) {
    try {
      dispatch("isLoading", true, { root: true });
      const response = await OrdersService.deleteAll();
      if (response) {
        dispatch("isLoading", false, { root: true });
        dispatch("trash");
        dispatch("updateDialog", payload.loading, { root: true });
        dispatch("snackBarVisibility", true, { root: true });
        dispatch("snackBarMessage", "Trash cleared successfully", {
          root: true
        });
      }
    } catch (e) {
      dispatch("isLoading", false, { root: true });
      if (isNetworkError(e)) {
        dispatch(
          "snackBarMessage",
          e?.message ?? "Unable to list trashed orders",
          {
            root: true
          }
        );
      } else {
        dispatch(
          "snackBarMessage",
          e?.response?.data?.message ?? "Unable to list trashed orders",
          { root: true }
        );
      }
    }
  },

  async dashboardCount({ commit, dispatch }) {
    try {
      dispatch("isLoading", true, { root: true });
      const response = await OrdersService.dashboardCount();
      if (response) {
        dispatch("isLoading", false, { root: true });
        commit("UPDATE_DASHBOARD_COUNT", response.data?.data);
      }
    } catch (e) {
      dispatch("isLoading", false, { root: true });
      if (isNetworkError(e)) {
        dispatch(
          "snackBarMessage",
          e?.message ?? "Unable to list trashed orders",
          {
            root: true
          }
        );
      } else {
        dispatch(
          "snackBarMessage",
          e?.response?.data?.message ?? "Unable to list trashed orders",
          { root: true }
        );
      }
    }
  },
  async dashboardGraph({ commit, dispatch }) {
    try {
      dispatch("isLoading", true, { root: true });
      const response = await OrdersService.dashboardGraph();
      if (response) {
        dispatch("isLoading", false, { root: true });
        commit("UPDATE_DASHBOARD_GRAPH", response.data?.data);
      }
    } catch (e) {
      dispatch("isLoading", false, { root: true });
      if (isNetworkError(e)) {
        dispatch(
          "snackBarMessage",
          e?.message ?? "Unable to list trashed orders",
          {
            root: true
          }
        );
      } else {
        dispatch(
          "snackBarMessage",
          e?.response?.data?.message ?? "Unable to list trashed orders",
          { root: true }
        );
      }
    }
  }
};

const getters: GetterTree<StoreState<IOrders>, RootState> = {
  getAllProducts: state => state.all,
  getOrderDetails: state => state.details,
  getTrashList: state => state.trash,
  getArchivesList: state => state.archives,
  getPagination: state => state.paginate,
  getDashboardGraph: state => state.dashboardGraph,
  getDashboardCount: state => state.dashboardCount
};

export const orders: Module<StoreState<IOrders>, RootState> = {
  namespaced,
  state,
  mutations,
  actions,
  getters
};
