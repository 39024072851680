import {
  IDialogAndButtonAction,
  IPaginate,
  IPaymentDetails,
  IResolveAccount,
  ITransactionHistory,
  RootState,
  StoreState
} from "@/types/types";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { isNetworkError } from "@/utils/helpers";
import { PaymentMethodService } from "@/services/payment-methods.service";

const namespaced = true;
const state: StoreState<IPaymentDetails> = {
  all: [],
  details: undefined,
  reset: {
    mobileMoneyNumber: ""
  },
  transactionList: [],
  pagination: {
    itemsPerPage: 20,
    page: 1,
    total: 0
  }
};

const mutations: MutationTree<StoreState<IPaymentDetails>> = {
  UPDATE_PAYMENT_LIST(state, payload: IPaymentDetails[]) {
    state.all = payload;
  },
  UPDATE_PAGINATION(
    state,
    payload: Pick<IPaginate, "itemsPerPage" | "page" | "total">
  ) {
    state.pagination = payload;
  },
  UPDATE_TRANSACTION_LIST(state, payload: ITransactionHistory[]) {
    state.transactionList = payload;
  }
};

const actions: ActionTree<StoreState<IPaymentDetails>, RootState> = {
  async paymentList({ commit, dispatch }) {
    try {
      dispatch("isPageLoading", true, { root: true });
      const response = await PaymentMethodService.list();
      if (response) {
        dispatch("isPageLoading", false, { root: true });
        commit("UPDATE_PAYMENT_LIST", response.data);
      }
    } catch (e) {
      dispatch("isPageLoading", false, { root: true });
      if (isNetworkError(e)) {
        dispatch("snackBarMessage", e?.message ?? "Unable to fetch data", {
          root: true
        });
      } else {
        dispatch(
          "snackBarMessage",
          e?.response?.data?.message ?? "Unable to fetch data",
          { root: true }
        );
      }
      dispatch("snackBarVisibility", true, { root: true });
    }
  },
  async addPaymentMethod(
    { commit, dispatch },
    payload: IDialogAndButtonAction<
      Pick<IResolveAccount, "account_number" | "bank_code"> & {
        isDefault: boolean;
      }
    >
  ) {
    try {
      dispatch("isLoading", true, { root: true });
      const resolveAccount = await PaymentMethodService.resolveAccount(
        payload.body
      );
      if (resolveAccount.data.data) {
        const { account_name, account_number } = resolveAccount.data.data;
        const recipient = await PaymentMethodService.createTransferRecipient({
          account_number,
          account_name,
          bank_code: payload.body.bank_code
        });
        if (recipient.data.status) {
          const {
            recipient_code,
            details: { account_name, account_number }
          } = recipient.data.data;
          const payment = await PaymentMethodService.createPaymentDetails({
            recipientCode: recipient_code,
            accountName: account_name,
            mobileMoneyNumber: account_number,
            isBank: false,
            isDefault: payload.body.isDefault
          });
          if (payment) {
            dispatch("snackBarVisibility", true, { root: true });
            dispatch("isLoading", false, { root: true });
            dispatch("snackBarMessage", "Payment details added successfully", {
              root: true
            });
            dispatch("updateDialog", payload.loading, { root: true });
            dispatch("resetFormValues", true, { root: true });
            dispatch("paymentList");
          }
        }
      } else {
        dispatch("snackBarVisibility", true, { root: true });
        dispatch("isLoading", false, { root: true });
        dispatch(
          "snackBarMessage",
          "Sorry, we were unable to add account details",
          { root: true }
        );
      }
    } catch (e) {
      dispatch("isLoading", false, { root: true });
      if (isNetworkError(e)) {
        dispatch("snackBarMessage", e?.message ?? "Unable to fetch data", {
          root: true
        });
      } else {
        dispatch(
          "snackBarMessage",
          e?.response?.data?.message ?? "Unable to fetch data",
          { root: true }
        );
      }
      dispatch("snackBarVisibility", true, { root: true });
    }
  },
  async transactionList({ commit, dispatch }, query?: string) {
    try {
      dispatch("isPageLoading", true, { root: true });
      const response = await PaymentMethodService.transactions(query);
      if (response) {
        const {
          docs,
          totalDocs,
          totalPages,
          page,
          limit
        } = response.data?.data;
        dispatch("isPageLoading", false, { root: true });
        commit("UPDATE_TRANSACTION_LIST", docs);
        commit("UPDATE_PAGINATION", {
          itemsPerPage: limit,
          page: page,
          total: totalDocs
        });
      }
    } catch (e) {
      dispatch("isPageLoading", false, { root: true });
      if (isNetworkError(e)) {
        dispatch("snackBarMessage", e?.message ?? "Unable to fetch data", {
          root: true
        });
      } else {
        dispatch(
          "snackBarMessage",
          e?.response?.data?.message ?? "Unable to fetch data",
          { root: true }
        );
      }
      dispatch("snackBarVisibility", true, { root: true });
    }
  },
  async paymentStatus(
    { commit, dispatch },
    payload: { id: string; query: string }
  ) {
    try {
      dispatch("isLoading", true, { root: true });
      const { id, query } = payload;
      const response = await PaymentMethodService.paymentStatus(id);
      if (response) {
        dispatch("isLoading", false, { root: true });
        dispatch("transactionList", query ?? `?page=1&size=15`);
      }
    } catch (e) {
      dispatch("isLoading", false, { root: true });
      if (isNetworkError(e)) {
        dispatch("snackBarMessage", e?.message ?? "Unable to fetch data", {
          root: true
        });
      } else {
        dispatch(
          "snackBarMessage",
          e?.response?.data?.message ?? "Unable to fetch data",
          { root: true }
        );
      }
      dispatch("snackBarVisibility", true, { root: true });
    }
  }
};

const getters: GetterTree<StoreState<IPaymentDetails>, RootState> = {
  getPaymentMethods: state => state.all,
  getResetFormValues: state => state.reset,
  getAllTransactionHistory: state => state.transactionList,
  getPagination: state => state.pagination
};

export const paymentDetails: Module<StoreState<IPaymentDetails>, RootState> = {
  namespaced,
  state,
  mutations,
  actions,
  getters
};
