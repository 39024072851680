import { Request } from "@/services/api";
import { IResolveAccount } from "@/types/types";
import { IPaymentDetails } from "../types/types";

export class PaymentMethodService {
  static list() {
    return Request().get(`/payment-details`);
  }

  static resolveAccount(
    body: Pick<IResolveAccount, "account_number" | "bank_code">
  ) {
    return Request().post(`/payment-details/resolve/account`, body);
  }

  static createTransferRecipient(body: IResolveAccount) {
    return Request().post(`/payment-details/transfer/recipient`, body);
  }

  static createPaymentDetails(body: IPaymentDetails) {
    return Request().post(`/payment-details/account/create`, body);
  }

  static transactions(query?: string) {
    return Request().get(`/transactions${query ? query : ""}`);
  }

  static paymentStatus(reference: string) {
    return Request().get(`/public/check/payment/status?reference=${reference}`);
  }
}
