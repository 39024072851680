import { IDeliveryTracking, RootState, StoreState } from "@/types/types";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { isNetworkError } from "@/utils/helpers";
import DeliveryTrackingService from "@/services/delivery-tracking.service";

const namespaced: boolean = true;
const state: StoreState<IDeliveryTracking> = {
  all: [],
  details: {
    id: "",
    deliveryProvider: "",
    deliveryRequest: "",
    trackingNumber: "",
    isDeliveryCompleted: false
  },
  completedDeliveries: [],
  enRouteDeliveries: []
};

const mutations: MutationTree<StoreState<IDeliveryTracking>> = {
  UPDATE_TRACKING_LIST(state, payload: IDeliveryTracking[]) {
    state.all = payload;
  },
  UPDATE_COMPLETED_DELIVERIES(state, payload: IDeliveryTracking[]) {
    state.completedDeliveries = payload;
  },
  UPDATE_ENROUTE_DELIVERIES(state, payload: IDeliveryTracking[]) {
    state.enRouteDeliveries = payload;
  }
};

const actions: ActionTree<StoreState<IDeliveryTracking>, RootState> = {
  async enRouteDeliveries({ commit, dispatch }, userId: string) {
    try {
      dispatch("isPageLoading", true, { root: true });
      const response = await DeliveryTrackingService.enRouteDeliveries(userId);
      if (response) {
        dispatch("isPageLoading", false, { root: true });
        commit("UPDATE_ENROUTE_DELIVERIES", response.data);
      }
    } catch (e) {
      dispatch("isPageLoading", false, { root: true });
      if (isNetworkError(e)) {
        dispatch("snackBarMessage", e?.message ?? "Unable to fetch data", {
          root: true
        });
      } else {
        dispatch(
          "snackBarMessage",
          e?.response?.data?.message ?? "Unable to fetch data",
          { root: true }
        );
      }
      dispatch("snackBarVisibility", true, { root: true });
    }
  },
  async completedDeliveries({ commit, dispatch }, userId: string) {
    try {
      dispatch("isPageLoading", true, { root: true });
      const response = await DeliveryTrackingService.completedDeliveries(
        userId
      );
      if (response) {
        dispatch("isPageLoading", false, { root: true });
        commit("UPDATE_COMPLETED_DELIVERIES", response.data);
      }
    } catch (e) {
      dispatch("isPageLoading", false, { root: true });
      if (isNetworkError(e)) {
        dispatch("snackBarMessage", e?.message ?? "Unable to fetch data", {
          root: true
        });
      } else {
        dispatch(
          "snackBarMessage",
          e?.response?.data?.message ?? "Unable to fetch data",
          { root: true }
        );
      }
      dispatch("snackBarVisibility", true, { root: true });
    }
  }
};

const getters: GetterTree<StoreState<IDeliveryTracking>, RootState> = {
  getCompletedDeliveries: state => state.completedDeliveries,
  getEnRouteDeliveries: state => state.enRouteDeliveries
};

export const deliveryTracking: Module<
  StoreState<IDeliveryTracking>,
  RootState
> = {
  namespaced,
  state,
  mutations,
  actions,
  getters
};
