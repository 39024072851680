import {
  IDialogAndButtonAction,
  INotifications,
  IPaginate,
  IPaymentDetails,
  IResolveAccount,
  ITransactionHistory,
  RootState,
  StoreState
} from "@/types/types";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { isNetworkError } from "@/utils/helpers";
import { NotificationsService } from "@/services/notifications.service";

const namespaced = true;
const state: StoreState<INotifications> = {
  all: [],
  details: undefined,
  reset: {
    mobileMoneyNumber: ""
  },

  pagination: {
    itemsPerPage: 20,
    page: 1,
    total: 0
  }
};

const mutations: MutationTree<StoreState<INotifications>> = {
  UPDATE_NOTIFICATION_LIST(state, payload: INotifications[]) {
    state.all = payload;
  },
  UPDATE_PAGINATION(
    state,
    payload: Pick<IPaginate, "itemsPerPage" | "page" | "total">
  ) {
    state.pagination = payload;
  },
  UPDATE_TRANSACTION_LIST(state, payload: ITransactionHistory[]) {
    state.transactionList = payload;
  }
};

const actions: ActionTree<StoreState<INotifications>, RootState> = {
  async list({ commit, dispatch }, payload) {
    try {
      const { query } = payload;
      const response = await NotificationsService.list(query);
      if (response) {
        const { docs, page, limit, totalPages } = response?.data?.data;
        commit("UPDATE_PAGINATION", {
          page,
          itemsPerPage: limit,
          total: totalPages
        });
        commit("UPDATE_NOTIFICATION_LIST", docs);
      }
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch("snackBarMessage", e?.message ?? "Unable to fetch data", {
          root: true
        });
      } else {
        dispatch(
          "snackBarMessage",
          e?.response?.data?.message ?? "Unable to fetch data",
          { root: true }
        );
      }
      dispatch("snackBarVisibility", true, { root: true });
    }
  },
  async markAsRead({ commit, dispatch }, payload) {
    try {
      const response = await NotificationsService.markAsRead(payload.id);
      if (response) {
        dispatch("list", { query: `?page=1&size=20` });
      }
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch("snackBarMessage", e?.message ?? "Unable to fetch data", {
          root: true
        });
      } else {
        dispatch(
          "snackBarMessage",
          e?.response?.data?.message ?? "Unable to fetch data",
          { root: true }
        );
      }
      dispatch("snackBarVisibility", true, { root: true });
    }
  },
  async removeNotifications({ commit, dispatch }, payload: { id: string }) {
    try {
      dispatch("isLoading", true, { root: true });
      const { id } = payload;
      const response = await NotificationsService.removeNotification(id);
      if (response) {
        dispatch("isLoading", false, { root: true });
        dispatch("snackBarVisibility", true, { root: true });
        dispatch("snackBarMessage", `Notification successfully deleted`, {
          root: true
        });
        dispatch("list", { query: `?page=1&size=20` });
      }
    } catch (e) {
      dispatch("isLoading", false, { root: true });
      if (isNetworkError(e)) {
        dispatch("snackBarMessage", e?.message ?? "Unable to fetch data", {
          root: true
        });
      } else {
        dispatch(
          "snackBarMessage",
          e?.response?.data?.message ?? "Unable to fetch data",
          { root: true }
        );
      }
      dispatch("snackBarVisibility", true, { root: true });
    }
  },
  async clearAllNotifications({ commit, dispatch }) {
    try {
      const response = await NotificationsService.clearAllNotifications();
      if (response) {
        dispatch("snackBarVisibility", true, { root: true });
        dispatch("snackBarMessage", `All notifications cleared successfully`, {
          root: true
        });
        dispatch("list", { query: `?page=1&size=20` });
      }
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch("snackBarMessage", e?.message ?? "Unable to fetch data", {
          root: true
        });
      } else {
        dispatch(
          "snackBarMessage",
          e?.response?.data?.message ?? "Unable to fetch data",
          { root: true }
        );
      }
      dispatch("snackBarVisibility", true, { root: true });
    }
  }
};

const getters: GetterTree<StoreState<INotifications>, RootState> = {
  getAllNotifications: state => state.all,
  getPagination: state => state.pagination
};

export const notifications: Module<StoreState<INotifications>, RootState> = {
  namespaced,
  state,
  mutations,
  actions,
  getters
};
