import { loadView, loadWidget } from "@/utils/helpers";

const routes = [
  {
    path: "/",
    redirect: "/signin"
  },
  {
    path: "/signin",
    name: "accounts.signin",
    component: loadView("greeters/Login"),
    meta: {
      pageTitle: "Sign In",
      requiresAuth: false
    }
  },
  {
    path: "/forgot-password",
    name: "accounts.forgot-password",
    component: loadView("greeters/ForgotPassword"),
    meta: {
      pageTitle: "Forgot Password",
      requiresAuth: false
    }
  },
  {
    path: "/reset-password",
    name: "accounts.reset-password",
    component: loadView("greeters/ResetPassword"),
    meta: {
      pageTitle: "Reset Password",
      requiresAuth: false
    }
  },
  {
    path: "/verification/:code",
    name: "accounts.verification",
    component: loadView("greeters/AccountVerification"),
    meta: {
      pageTitle: "Account Verification",
      requiresAuth: false
    }
  },
  {
    path: "/actions/success",
    name: "accounts.actions.success",
    component: loadWidget("pages/SuccessPage"),
    meta: {
      pageTitle: "Success Page",
      requiresAuth: false
    }
  },
  {
    path: "/home",
    name: "home",
    component: loadView("Master"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: loadView("pages/dashboard/Index"),
        meta: {
          pageTitle: "Home",
          requiresAuth: true
        }
      },
      {
        path: "/orders",
        name: "orders",
        component: loadView("pages/orders/Index"),
        meta: {
          pageTitle: "Orders",
          requiresAuth: true
        }
      },
      {
        path: "/feedbacks",
        name: "feedbacks",
        component: loadView("pages/feedback/Index"),
        meta: {
          pageTitle: "Feedbacks",
          requiresAuth: true
        }
      },
      {
        path: "/transactions",
        name: "transactions",
        component: loadView("pages/transactions/Transactions"),
        meta: {
          pageTitle: "Transactions",
          requiresAuth: true
        }
      },
      {
        path: "/payment-methods",
        name: "payment-methods",
        component: loadView("pages/payments/PaymentMethod"),
        meta: {
          pageTitle: "Payment Methods",
          requiresAuth: true
        }
      },
      {
        path: "/deliveries/in-transit",
        name: "deliveries.in-transit",
        component: loadView("pages/tracking/InTransit"),
        meta: {
          pageTitle: "En-Route Deliveries",
          requiresAuth: true
        }
      },
      {
        path: "/deliveries/completed",
        name: "deliveries.completed",
        component: loadView("pages/tracking/Completed"),
        meta: {
          pageTitle: "Completed Deliveries",
          requiresAuth: true
        }
      },
      {
        path: "/disputes",
        name: "disputes",
        component: loadView("pages/transactions/Transactions"),
        meta: {
          pageTitle: "Transactions",
          requiresAuth: true
        }
      },
      {
        path: "/archives",
        name: "archives",
        component: loadView("pages/Archives"),
        meta: {
          pageTitle: "Archives",
          requiresAuth: true
        }
      },
      {
        path: "/trash",
        name: "trash",
        component: loadView("pages/Trash"),
        meta: {
          pageTitle: "Trash",
          requiresAuth: true
        }
      }
    ]
  }
  // {
  //   path: "*",
  //   redirect: "/404"
  // },
  // {
  //   path: "/404",
  //   name: "404",
  //   component: loadWidget("pages/Error404")
  // }
];

export default routes;
