import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueApexCharts from "vue-apexcharts/dist/vue-apexcharts";

import Clipboard from "vue-clipboard2";
import * as firebase from "firebase";
import { firebaseConfig } from "@/config/firebase";

firebase.initializeApp(firebaseConfig);
require("./assets/css/main.css");
Vue.use(Clipboard);
Vue.component("apexcharts", VueApexCharts);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
