import Axios from "axios";

export const Api = () => {
  return Axios.create({
    baseURL: `${process.env.VUE_APP_BUSINESS_API}${
      process.env.NODE_ENV === "production" ? "/v1" : "/api/v1"
    }`,
    timeout: 80000,
    headers: {
      "x-api-key": "",
      "x-api-secret": ""
    }
  });
};
