import { Api } from "./externalApi";

export default class DeliveryTrackingService {
  static enRouteDeliveries(userId: string) {
    return Api().get(`/delivery-tracking/sellers/${userId}/en-route`);
  }

  static completedDeliveries(userId: string) {
    return Api().get(`/delivery-tracking/sellers/${userId}/completed-delivery`);
  }
}
