import { Request } from "@/services/api";
import { IUser } from "@/types/types";

class UsersService {
  static find(id: string) {
    return Request().get(`/users/${id}`);
  }

  /**
   * Endpoint for getting list of users
   */
  static list() {
    return Request().get(`/users`);
  }

  static create(payload: IUser) {
    return Request().post(`/users`, payload);
  }

  static update(id: string | undefined, payload: IUser) {
    return Request().put(`/users/${id}`, payload);
  }

  static delete(id: string) {
    return Request().delete(`/users/${id}`);
  }
}

export default UsersService;
