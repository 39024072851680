import { Request } from "@/services/api";

export class NotificationsService {
  static list(query: string) {
    return Request().get(`/notifications${query ? query : ""}`);
  }
  static markAsRead(id: string) {
    return Request().get(`/notifications/${id}/mark/as/read`);
  }
  static removeNotification(id: string) {
    return Request().delete(`/notifications/${id}`);
  }
  static clearAllNotifications() {
    return Request().delete(`/notifications`);
  }
}
