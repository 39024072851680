import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import {
  AuthState,
  INotificationTokens,
  IResetPassword,
  LoginState,
  RootState
} from "@/types/types";
import { isNetworkError } from "@/utils/helpers";
import router from "@/router";
import AuthService from "@/services/auth.service";

import SecureLS from "secure-ls";

let ls = new SecureLS({
  isCompression: false
});
const namespaced: boolean = true;

const state: AuthState = {
  user: {
    details: {
      id: "",
      name: "",
      username: "",
      email: "",
      mobile: "",
      slug: "",
      storeName: "",
      code: ""
    },
    token: "",
    userType: ""
  },
  isAccountAlreadyVerified: {
    message: "",
    state: false
  }
};

const mutations: MutationTree<AuthState> = {
  UPDATE_TOKEN(state, payload: { access_token: string; userType: string }) {
    state.user.token = payload?.access_token;
    state.user.userType = payload?.userType;
  },
  UPDATE_USER_DETAILS(state, payload) {
    state.user.details = payload;
  },
  UPDATE_ACCOUNT_ALREADY_VERIFIED(state, payload: any) {
    state.isAccountAlreadyVerified = payload;
  }
};

const actions: ActionTree<AuthState, RootState> = {
  async login({ commit, dispatch }, payload: LoginState) {
    try {
      dispatch("isLoading", true, { root: true });
      const response = await AuthService.login(payload);
      if (response) {
        commit("UPDATE_TOKEN", {
          access_token: response.data.data?.accessToken,
          userType: response.data.data?.user?.userType
        });
        dispatch("isLoading", false, { root: true });
        await router.push({ name: "dashboard" });
      }
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch("snackBarMessage", e?.message, {
          root: true
        });
      } else {
        dispatch("snackBarMessage", e.response.data?.message, { root: true });
      }
      dispatch("snackBarVisibility", true, { root: true });
      dispatch("isLoading", false, { root: true });
    }
  },
  async registerNotificationTokens(
    { commit, dispatch },
    payload: INotificationTokens
  ) {
    try {
      await AuthService.registerTokens(payload);
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch("snackBarMessage", e?.message, {
          root: true
        });
      } else {
        dispatch("snackBarMessage", e.response.data?.message, { root: true });
      }
      dispatch("snackBarVisibility", true, { root: true });
      dispatch("isLoading", false, { root: true });
    }
  },
  async forgotPassword({ dispatch }, payload: { email: string }) {
    try {
      dispatch("isLoading", true, { root: true });
      const response = await AuthService.forgotPassword(payload);
      if (response) {
        dispatch("isLoading", false, { root: true });
        router.replace({
          name: "accounts.actions.success"
        });
      }
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch("snackBarMessage", e?.message, {
          root: true
        });
      } else {
        dispatch("snackBarMessage", e.response.data?.message, { root: true });
      }
      dispatch("snackBarVisibility", true, { root: true });
      dispatch("isLoading", false, { root: true });
    }
  },
  async resetPassword({ dispatch }, payload: IResetPassword) {
    try {
      dispatch("isLoading", true, { root: true });
      const response = await AuthService.resetPassword(payload);
      if (response) {
        dispatch("isLoading", false, { root: true });
        dispatch("snackBarMessage", "Password reset successfully", {
          root: true
        });
        dispatch("snackBarVisibility", true, { root: true });
        setTimeout(() => {
          router.replace({
            name: "accounts.signin"
          });
        }, 2000);
      }
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch("snackBarMessage", e?.message, {
          root: true
        });
      } else {
        dispatch("snackBarMessage", e.response.data?.message, { root: true });
      }
      dispatch("snackBarVisibility", true, { root: true });
      dispatch("isLoading", false, { root: true });
    }
  },
  async verifyAccount({ dispatch, commit }, payload: { code: string }) {
    try {
      dispatch("isLoading", true, { root: true });
      const response = await AuthService.verifyAccount(payload);
      if (response) {
        commit("UPDATE_ACCOUNT_ALREADY_VERIFIED", {
          message: "",
          status: false
        });
        dispatch("snackBarMessage", "Account Verified Successfully", {
          root: true
        });
        dispatch("snackBarVisibility", true, { root: true });
        dispatch("isLoading", false, { root: true });
      }
    } catch (e) {
      if (e.response.status === 404) {
        commit("UPDATE_ACCOUNT_ALREADY_VERIFIED", {
          message: e.response.data.message,
          status: true
        });
      }
      if (isNetworkError(e)) {
        dispatch("snackBarMessage", e?.message, {
          root: true
        });
      } else {
        dispatch("snackBarMessage", e.response.data?.message, { root: true });
      }
      dispatch("snackBarVisibility", true, { root: true });
      dispatch("isLoading", false, { root: true });
    }
  },
  async me({ commit, dispatch }) {
    try {
      const response = await AuthService.me();
      const { _id, name, accountNumber, username, phone } = response.data?.data;
      commit("UPDATE_USER_DETAILS", {
        name,
        email: accountNumber,
        username,
        mobile: phone,
        id: _id
      });
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch("snackBarMessage", e?.message, {
          root: true
        });
      } else {
        dispatch("snackBarMessage", e.response.data?.message, { root: true });
      }
      dispatch("snackBarVisibility", true, { root: true });
    }
  },
  async logOut({ commit, dispatch }, payload) {
    ls.removeAll();
    commit("UPDATE_TOKEN", payload);
    await router.replace({
      name: "accounts.signin"
    });
  }
};

const getters: GetterTree<AuthState, RootState> = {
  isAuthenticated: state => !!state.user.token,
  getToken: state => state.user.token,
  getUserDetails: state => state.user.details,
  getUserType: state => state.user.userType,
  getIsAccountAlreadyVerified: state => state.isAccountAlreadyVerified
};

export const auth: Module<AuthState, RootState> = {
  namespaced,
  state,
  mutations,
  actions,
  getters
};
