import Vue from "vue";
import Vuetify from "vuetify"; // import Vuetify from "vuetify/lib"
import "vuetify/dist/vuetify.css";
import "material-design-icons-iconfont/dist/material-design-icons.css"; // Ensure you are using css-loader
import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#ff0055",
        secondary: "#ffe3ec",
        accent: "#001122", //1c1c1c
        error: colors.red.accent3,
        ripple: colors.grey.lighten1
      },
      dark: {
        primary: colors.blue.lighten3
      }
    }
  },
  icons: {
    iconfont: "md"
  }
});
