import {
  IDialogAndButtonAction,
  INotifications,
  IPaginate,
  IPaymentDetails,
  IResolveAccount,
  ITransactionHistory,
  RootState,
  StoreState
} from "@/types/types";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { isNetworkError } from "@/utils/helpers";
import { FeedbackService } from "@/services/feedback.service";

const namespaced = true;

export interface IFeedback {
  msisdn: string;
  comment: string;
}

const state: StoreState<IFeedback> = {
  all: [],
  pagination: {
    itemsPerPage: 20,
    page: 1,
    total: 0
  }
};

const mutations: MutationTree<StoreState<IFeedback>> = {
  UPDATE_FEEDBACK_LIST(state, payload: IFeedback[]) {
    state.all = payload;
  },
  UPDATE_PAGINATION(
    state,
    payload: Pick<IPaginate, "itemsPerPage" | "page" | "total">
  ) {
    state.pagination = payload;
  }
};

const actions: ActionTree<StoreState<IFeedback>, RootState> = {
  async list({ commit, dispatch }, payload) {
    try {
      dispatch("isPageLoading", true, { root: true });
      const { query } = payload;
      const response = await FeedbackService.list(query);
      if (response) {
        dispatch("isPageLoading", false, { root: true });
        const { docs, page, limit, totalPages } = response?.data?.data;
        commit("UPDATE_PAGINATION", {
          page,
          itemsPerPage: limit,
          total: totalPages
        });
        commit("UPDATE_FEEDBACK_LIST", docs);
      }
    } catch (e) {
      dispatch("isPageLoading", false, { root: true });
      if (isNetworkError(e)) {
        dispatch("snackBarMessage", e?.message ?? "Unable to fetch data", {
          root: true
        });
      } else {
        dispatch(
          "snackBarMessage",
          e?.response?.data?.message ?? "Unable to fetch data",
          { root: true }
        );
      }
      dispatch("snackBarVisibility", true, { root: true });
    }
  }
};

const getters: GetterTree<StoreState<IFeedback>, RootState> = {
  getAllFeedback: state => state.all,
  getPagination: state => state.pagination
};

export const feedbacks: Module<StoreState<IFeedback>, RootState> = {
  namespaced,
  state,
  mutations,
  actions,
  getters
};
