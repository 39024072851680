<template>
  <v-app light style="background: white !important;">
    <router-view />
    <div
      v-if="snackbar"
      class="notification-container animate__animated animate__bounce animate__repeat-3 vx-shadow pa-3"
      style="width: 320px;max-width: 350px;position: absolute;bottom: 5%;right: 10%;background-color: white;border-radius: 8px;"
    >
      <img
        src="@/assets/images/notification.png"
        style="width: 50px;position:absolute;margin-top: -40px;"
      />
      <v-btn
        style="position:absolute;right: 4px;top:4px"
        icon
        small
        @click="snackbar = false"
        fab
        color="primary"
      >
        <i class="material-icons-outlined">close</i>
      </v-btn>
      <div class="mt-4">
        <h3 class="ft pb-1" style="font-size: 15px;">
          {{ title }}
        </h3>
        <p class="ft font-weight-medium" style="font-size: 13px;">
          {{ text }}
        </p>
      </div>
    </div>
  </v-app>
</template>

<script>
import { Component, Vue, Watch } from "vue-property-decorator";
import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/messaging";

@Component
export default class App extends Vue {
  snackbar = false;
  text = "";
  title = "";
  checkConnection() {
    if (navigator.onLine) {
      this.$store.dispatch("internet", false, { root: true });
    } else {
      this.$store.dispatch("internet", true, { root: true });
    }
  }

  @Watch("snackbar")
  onSnackBarStateChange(state) {
    if (state) {
      setTimeout(() => {
        this.snackbar = false;
        this.text = "";
        this.title = "";
      }, 5000);
    }
  }
  created() {
    if (process.env.NODE_ENV === "production") {
      // TODO: have to make request whenever the page detects internet and reloads the page
      setInterval(() => {
        this.checkConnection();
      }, 4000);
    }
  }
  mounted() {
    const messaging = firebase.messaging();
    messaging.onMessage(payload => {
      this.snackbar = true;
      const { notification } = payload;
      this.title = notification?.title;
      this.text = notification?.body;
    });
    navigator.serviceWorker.addEventListener("message", event => {
      this.$store.dispatch("notifications/list", { query: `?page=1&size=20` });
    });
  }
}
</script>
<style></style>
